import { createFeatureSelector, createSelector } from '@ngrx/store';
import * as fromHome from './home.reducer';
import { Business } from 'src/app/shared/models/firestore.model';
import { BusinessVM } from 'src/app/shared/models/business.vm';
import { AuthSelectors } from 'src/app/auth/store';
import { CountryCodeVM } from '../../shared/models/country-code.model';
import { mapPaymentMethodToPaymentMethodType } from './mappings';

export const selectHomeState = createFeatureSelector<fromHome.State>(fromHome.homeFeatureKey);

export const selectCountries = createSelector(AuthSelectors.supportedCountryCodes, countries => countries);

export const selectBusinessAggregate = createSelector(selectHomeState, state => state.businessAggregate);
export const selectBusiness = createSelector(selectBusinessAggregate, selectCountries, (aggregate, countries) =>
  aggregate?.business ? mapBusinessToViewModel(aggregate.business, countries) : null,
);
function mapBusinessToViewModel(business: Business, countries: CountryCodeVM[]): BusinessVM {
  const country = countries.find(c => c.code === business.country_code);
  return {
    id: business.id,
    name: business.name,
    companyCode: business.company_code,
    companySize: business.company_size,
    countryCode: business.country_code,
    dashboardUrl: business.dashboard_url,
    paymentMethodTypes: business.booker_payment_methods?.map(type => mapPaymentMethodToPaymentMethodType(type)),
    partnerDiscountPercent: business.partner_discount_percent,
    selfInvitationEmailDomains: business.self_invitation_email_domains,
    emailDomainString: business.self_invitation_email_domains.join(', '),
    countryCenter: country.center,
  };
}
export const selectBusinessId = createSelector(selectBusiness, business => business?.id);

export const selectFailedPayments = createSelector(selectHomeState, state => state.failedPayments);
export const selectPaymentsCount = createSelector(selectFailedPayments, payments => payments?.length);
