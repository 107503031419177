import { PaymentMethod } from './payment-methods.model';
import { Payment } from './payment.model';

export interface Entity {
  meta_ts: number;
  meta_op: MetaOp;
}

export enum MetaOp {
  CREATE = 0,
  UPDATE = 1,
  DELETE = 2,
}

export interface AppFeatureToggle {
  enabled_business_ids: string[];
  is_enabled_for_all: boolean;
  disable_from?: number;
  disable_to?: number;
}

export interface NearbyDriverLimitConfig extends AppFeatureToggle {
  limit: number;
  interval: number;
}

export type AppConfig = { [id: string]: AppFeatureToggle };

export interface BusinessAggregate extends Entity {
  business: Business;
  agents: Agent[];
  cards: Card[];
  payment_cards: PaymentCard[];
}

export interface Card extends Entity {
  agent_ids: string[];
  business_id: string;
  id: string;
  payment_card_id: string;
}

export interface PaymentCard extends Entity {
  brand: string;
  brand_name: string;
  business_id: string;
  id: string;
  summary: string;
  expiry_month: number;
  expiry_year: number;
  expiringSoon?: boolean;
  expired?: boolean;
}

export interface AgentAggregate extends Entity {
  agent: Agent;
  user: User;
}

export interface Agent extends Entity {
  id: string;
  business_id: string;
  allow_custom_pickup_location: boolean;
  pickup_locations: Location[];
  phone: string;
}

export interface Business extends Entity {
  id: string;
  name: string;
  company_code: string;
  company_size: string;
  country_code: string;
  dashboard_url: string;
  booker_payment_methods: PaymentMethod[];
  partner_discount_percent: number;
  self_invitation_email_domains: string[];
}

export interface ExternalAccountManagerAggregate extends Entity {
  external_account_manager: ExternalAccountManager;
  user: User;
}

export interface ExternalAccountManager extends Entity {
  id: string;
  business_id: string;
}

export interface User extends Entity {
  id: string;
  email: string;
  email_verified: boolean;
}

export interface RiderInvitation extends Entity {
  id: string;
  email: string;
  rider_id: string;
  accepted_at: number;
}

export interface RiderAggregate extends Entity {
  rider: Rider;
}

export interface Rider extends Entity {
  id: string;
  first_name: string;
  last_name: string;
}

export interface RiderBusinessProfile extends Entity {
  id: string;
  email: string;
  rider_id: string;
  invitation_id: string;
}

export interface RiderInvitationAggregate extends Entity {
  invitation: RiderInvitation;
  business_profile: RiderBusinessProfile;
}

export interface BookingAggregate extends Entity {
  booking: Booking;
  job: Job;
  trip: Trip;
  claims: Claim[];
  trip_price: BookingTripPrice;
  pickup_eta: PickupEta;
  productPackage?: Package;
  dispatch_trigger?: DispatchTrigger;
  quote?: Quote;
  vehicle?: Vehicle;
  driver?: Driver;
  vehicleType?: VehicleType;
  payment?: Payment;
  card_payment?: CardPayment;
}

export interface Claim {
  booking_id: string;
  driver_id: string;
  meta_ts: number;
}

export interface BookingTripPrice extends Entity {
  customer_net_amount: Money;
  external_fees_amount?: Money;
}

export interface DispatchTrigger extends Entity {
  fire_at: number;
  fired_at: number;
}

export interface Job extends Entity {
  id: string;
  accepted_at: number;
  completed_at: number;
  ended_at: number;
  in_progress_at: number;
  driver_id: string;
  vehicle_id: string;
  driver?: Driver;
  vehicle?: Vehicle;
}

export interface Driver {
  id: string;
  first_name: string;
  last_name: string;
  telemetry?: DriverTelemetry;
}

export interface Vehicle {
  id: string;
  license_plate: string;
  make: string;
  model: string;
}

export interface Trip extends Entity {
  ended_at: number;
  polyline: string | null;
  driver: Driver;
  vehicle: Vehicle;
}

export interface PickupEta extends Entity {
  eta: number;
}

export interface Booking extends Entity {
  id: string;
  area_id: string;
  business_id: string;
  created_at: number;
  pickup_at?: number;
  pickup: Location;
  dropoff: Location;
  notes: Note[];
  phone_number: string;
  quote_id: string;
  cancelled_at: number;
  expired_at: number;
  vehicle_type_id: string;
  payment_method: PaymentMethod;
  package_id: string;
  is_prebooking?: boolean;
  feature_ids: string[];
}

export interface CardPayment extends Entity {
  payment_method: PaymentMethod;
}

export interface Location extends Entity {
  formatted_address: string;
  lat: number;
  lng: number;
  name: string;
}

export interface Area extends Entity {
  id: string;
  time_zone_id: string;
}

export interface Note extends Entity {
  body: string;
}

export interface Quote extends Entity {
  id: string;
  total_price: Money;
  business_id: string;
  external_fees_amount?: Money;
  polyline?: string;
}

export interface Money extends Entity {
  value: number;
  currency: string;
  display: string;
}

export interface AreaPackage extends Entity {
  package_ids: string[];
}

export interface Package extends Entity {
  id: string;
  name: string;
  icon: string;
  sort_order: number;
  vehicle_type_id: string;
  feature_ids: string[];
}

export interface VehicleType extends Entity {
  id: string;
  name: string;
  seats: number;
}

export interface Feature extends Entity {
  id: string;
  icon: string;
  name: string;
  count: number;
}

export type PlatformSettings = {
  [key in
    | 'b2b.prebooking_max_duration_to_pickup'
    | 'b2b.prebooking_min_duration_to_pickup'
    | 'b2b.allow_contact_driver_after_trip_duration']: PlatformSetting;
};

export interface PlatformSetting extends Entity {
  name: string;
  values: PlatformSettingValue[];
}

export interface PlatformSettingValue {
  condition: SettingValueCondition;
  value: number;
}

interface SettingValueCondition {
  type: string;
  value: string;
}

export interface BusinessNote {
  note?: string;
  sites: BusinessSiteNote[];
}

export interface BusinessSiteNote {
  business_site_id: string;
  note: string;
}

export interface DriverTelemetry extends Entity {
  direction: number;
  driver_id: string;
  id: string;
  latitude: number;
  longitude: number;
  position_timestamp: number;
  driverStatus?: DriverStatus;
}

export interface DriverState extends Entity {
  status: DriverStatus;
  vehicle_id: string;
}

export interface DriverStatus {
  busy_reason: number;
  type: DriverStateStatusType;
}

export enum DriverStateStatusType {
  UNDEFINED = 0,
  OFFLINE = 1,
  ONLINE = 2,
  BUSY = 3,
}
