import { createReducer, on } from '@ngrx/store';
import { BusinessAggregate } from 'src/app/shared/models/firestore.model';
import * as HomeActions from './home.actions';
import { Payment } from 'src/app/shared/models/payment.model';

export const homeFeatureKey = 'home';

export interface State {
  businessAggregate: BusinessAggregate;
  failedPayments: Payment[];
}

export const initialState: State = {
  businessAggregate: undefined,
  failedPayments: undefined,
};

export const reducer = createReducer(
  initialState,

  on(HomeActions.businessAggregateChanged, (state, action) => ({
    ...state,
    businessAggregate: action.aggregate,
  })),
  on(HomeActions.failedPaymentsChanged, (state, action) => ({
    ...state,
    failedPayments: action.failedPayments,
  })),
);
