import { createAction, props } from '@ngrx/store';
import { BusinessAggregate } from 'src/app/shared/models/firestore.model';
import { Payment } from 'src/app/shared/models/payment.model';

export const businessAggregateChanged = createAction(
  '[Home] Business aggregate changed',
  props<{ aggregate: BusinessAggregate }>(),
);

export const failedPaymentsChanged = createAction(
  '[Home] Failed payments changed',
  props<{ failedPayments: Payment[] }>(),
);
