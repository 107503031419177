import { PaymentMethod, PaymentMethodType } from '../../shared/models/payment-methods.model';

export function mapPaymentMethodToPaymentMethodType(paymentMethod: PaymentMethod): PaymentMethodType {
  switch (paymentMethod.type) {
    case PaymentMethodType.IN_PERSON:
      return PaymentMethodType.IN_PERSON;
    case PaymentMethodType.INVOICE:
      return PaymentMethodType.INVOICE;
    case PaymentMethodType.CARD:
      return PaymentMethodType.CARD;
    default:
      throw new Error('Invalid payment method');
  }
}
